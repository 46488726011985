import './App.css';
import NavBar from './components/Navbar';
import Body from './components/Body';
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/FooterNew';
import { CookieSetting } from "cookiesetting-component";

function App() {

  return (
    <>
    <CookieSetting 
        privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK} 
        configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
        analytics={process.env.REACT_APP_ANALYTICS_PATH}
      />
      <NavBar />
      <Body />
      <Footer />
      <ScrollToTop />
    </>
  );
}

export default App;
